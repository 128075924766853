activeCustomMarker = undefined

createCustomMapMarker = ({ OverlayView = google.maps.OverlayView, location, map, isMobile }) ->

  class CustomMapMarker extends OverlayView

    MAP_STYLES: { 'eLocker': 'locker-pin', 'Group Parking': 'group-pin', 'Bike Hangar': 'hangar-pin', 'Vendor': 'vendor-pin' }
    PROBLEM_STYLES: { 'Work Order': 'work-order-pin', 'Problem report': 'problem-report-pin', 'Both problems': 'both-problem-pin' }

    constructor: (location, map, isMobile) ->
      super()
      @latlng = new google.maps.LatLng(location.latitude, location.longitude)
      @location = location
      @map = map
      @isMobile = isMobile
      @setMap(map)

    createDiv: ->
      @div = document.createElement('div')

      if @location.type == 'friendly_type'
        @div.className = 'map-marker '+ @MAP_STYLES[@location.location_friendly_type]
      else
        count_problems = @location.problem_reports.length + @location.work_orders.length
        if count_problems > 9
          @div.innerHTML = '9+'
        else
          @div.innerHTML = count_problems
        if @location.problem_reports.length > 0 && @location.work_orders.length > 0
          @div.className = 'map-marker count-pin '+ @PROBLEM_STYLES['Both problems']
        else
          if @location.problem_reports.length > 0
             @div.className = 'map-marker count-pin '+ @PROBLEM_STYLES['Problem report']
          else
             @div.className = 'map-marker count-pin '+ @PROBLEM_STYLES['Work Order']

      return @addDesktopListener() unless @isMobile
      return @addTabletListener() if DeviceResolver.isTablet()
      @addMobileListener()

    addDesktopListener: ->
      self = this
      google.maps.event.addDomListener(@div, 'mouseleave', (event) ->
        activeCustomMarker.deactivate() if activeCustomMarker
        self.deactivate()
      )
      google.maps.event.addDomListener(@div, 'mouseenter', (event) ->
        activeCustomMarker.deactivate() if activeCustomMarker
        self.activate()
      )
      google.maps.event.addDomListener(@div, 'click', (event) ->
        self.showLocationDetail()
        self.clickActivate()
      )

    addTabletListener: ->
      self = this
      google.maps.event.addDomListener(@div, 'click', (event) ->
        activeCustomMarker.deactivate() if activeCustomMarker
        self.showLocationDetail()
        self.activate()
      )

    addMobileListener: ->
      self = this
      google.maps.event.addDomListener(@div, 'click', (event) ->
        activeCustomMarker.deactivate() if activeCustomMarker
        map.setCenter(self.getPosition())
        self.activate()
      )

    appendDivToOverlay: ->
      @getPanes().overlayImage.appendChild(@div)

    positionDiv: ->
      point = @getProjection().fromLatLngToDivPixel(@latlng)
      if (point)
        # anchor is middle bottom
        @div.style.left = point.x - (@div.offsetWidth / 2) + 'px'
        @div.style.top = point.y - @div.offsetHeight + 'px'

    draw: ->
      if (!@div)
        @createDiv()
        @appendDivToOverlay()
      @positionDiv()

    getPosition: ->
      @latlng

    addMapPinStyle: ->
      if @location.type == 'friendly_type'
        @div.classList.add(@MAP_STYLES[@location.location_friendly_type] + '-active')
      else
        @div.classList.add('new-active-pin')
        if @location.problem_reports.length > 0 && @location.work_orders.length > 0
          @div.classList.add(@PROBLEM_STYLES['Both problems'] + '-active')
        else
          if @location.problem_reports.length > 0
            @div.classList.add(@PROBLEM_STYLES['Problem report'] + '-active')
          else
            @div.classList.add(@PROBLEM_STYLES['Work Order'] + '-active')

    removeMapPinStyle: ->
      return if @div.classList.contains('click')

      if @location.type == 'friendly_type'
        @div.classList.remove(@MAP_STYLES[@location.location_friendly_type] + '-active')
      else
        @div.classList.remove('new-active-pin')
        if @location.problem_reports.length > 0 && @location.work_orders.length > 0
          @div.classList.remove(@PROBLEM_STYLES['Both problems'] + '-active')
        else
          if @location.problem_reports.length > 0
            @div.classList.remove(@PROBLEM_STYLES['Problem report'] + '-active')
          else
            @div.classList.remove(@PROBLEM_STYLES['Work Order'] + '-active')

    removeClassesFromInactive = (elem) ->
      elem.className = elem.className.replace(/[a-z]{1,}-pin-active/, '')
      elem.classList.remove('active-pin')
      elem.classList.remove('previously-clicked')
      return elem

    clickActivate: ->
      return unless @div
      self = this

      @div.classList.add('click')
      self.addMapPinStyle()
      $('.click').not(@div).removeClass('click').addClass('previously-clicked')
      $('.previously-clicked').removeClass('active')
      element = document.getElementsByClassName('previously-clicked')[0]
      if element
        removeClassesFromInactive(element)

    activate: ->
      return unless @div

      self = this
      activeCustomMarker = this
      @div.classList.add('active-pin')
      self.addMapPinStyle()
      active_div = @div
      possibleRemove =  ->
        active_div.classList.remove('new-active-pin')

      setTimeout(possibleRemove, 500)
      callback = ->
        unless DeviceResolver.isTablet()
          self.customBubble = window.createCustomMapBubble(marker: self, location: self.location, map: self.map)
      setTimeout(callback, 100)

    deactivateClick: ->
      return unless @div

      _content = $(this).next('.click')
      $('.click').not(_content).removeClass('click')

    deactivate: ->
      return unless @div

      activeCustomMarker = undefined
      @div.classList.remove('active-pin') unless @div.classList.contains('click')
      self = this
      self.removeMapPinStyle()
      callback = ->
        self.customBubble.remove() if self.customBubble
      setTimeout(callback, 100)

    show: ->
      return unless @div

      @div.style.display = 'block';
      @positionDiv()

    hide: ->
      return unless @div

      @deactivate() if activeCustomMarker == this
      @div.style.display = 'none';

    showLocationDetail: ->
      self = this
      Rails.ajax(
        type: "get",
        url: "/maps/locations/" + @location.url_id + "/sidebar_info",
        data:  "",
        dataType: "script",
        beforeSend: (data) ->
          $('.miles-away').hide()
        success: (data) ->
          CustomMapMarker.showDistanceForPosition({ latitude: self.location.latitude, longitude: self.location.longitude })
        error: (data) ->
      )

    @showDistanceForPosition: (locationPosition) ->
      return unless navigator.geolocation

      navigator.geolocation.getCurrentPosition((userPosition) ->
        distance = window.distanceServices.distanceInBetweenGPSCoordinates(locationPosition, userPosition.coords)
        $('#miles-away-number').text(Number(Math.round(distance + 'e' + 1) + 'e-' + 1))
        $('.miles-away').show()
      )

  window.CustomMapMarker = CustomMapMarker

  return new CustomMapMarker(location, map, isMobile)

class CustomMapMarkersCollection

  constructor: (locations, map) ->
    @allMarkers = {}
    @activeMarkers = {}
    @isMobile = DeviceResolver.isMobileOrTablet()
    @activateGlobalListeners()
    @build(locations, map)

  activateGlobalListeners: ->
    return unless @isMobile

    $(document).on('click', (event) ->
      target = $(event.target)
      return if target.closest('.map-bubble').length > 0 || target.closest('.map-marker').length > 0
      activeCustomMarker.deactivate() if activeCustomMarker && !DeviceResolver.isTablet()
    )

  build: (locations, map) ->
    self = this
    $.each locations, (index, location) ->
      customMarker = createCustomMapMarker(location: location, map: map, isMobile: self.isMobile)
      self.allMarkers[location.id] = customMarker
      self.activeMarkers[location.id] = customMarker

  hide: (location_id) =>
    return unless @activeMarkers[location_id]
    @activeMarkers[location_id].hide()

  show: (location_id) =>
    return unless @activeMarkers[location_id]
    @activeMarkers[location_id].show()

  showLocationsInBox: (lat0, lng0, lat1, lng1) ->
    self = this
    $.each @allMarkers, (locationId, marker) ->
      if window.distanceServices.inBoundingBox(lat0, lng0, lat1, lng1, marker.latlng.lat(), marker.latlng.lng())
        self.activeMarkers[locationId] = marker
        marker.show()
      else
        delete self.activeMarkers[locationId]
        marker.hide()

  activate: (location_id) =>
    activeCustomMarker.deactivate() if activeCustomMarker
    @allMarkers[location_id].activate()

window.CustomMapMarkersCollection = CustomMapMarkersCollection
