import consumer from "./consumer"

function ahVerificationConnection() {
  if (
    !continueIfAllowed(
      'access_hub_verification',
      [
        'photo_id_picture',
        'user_picture'
      ]
    )
  ) return;

  let accessHubId = $('#ah-verification-container').data('access-hub-id')
  if (accessHubId <= 0) return false

  window.accessHubVerificationChannel = consumer.subscriptions.create(
    {
      channel: "AccessHubVerificationChannel",
      access_hub_id: accessHubId
    },
    {
      connected() {
        console.log("connected");
      },

      disconnected() {
        console.log("disconnected");
      },

      escalate() {
        this.perform('escalate', { access_hub_id: accessHubId })
      },

      received(data) {
        console.log("received", data);
        console.log("awaitingPhoto: " + this.awaitingPhoto())
        if (!this.awaitingPhoto()) return
        let step = this.getVerificationStep()
        console.log("step: " + step)
        console.log("includes...: " + ![1, 2].includes(step))
        if (![1, 2].includes(step)) return
        let photoEvent = this.getPhotoEvent()
        console.log("photoEvent: " + photoEvent)
        if (photoEvent != data.photo_event) return

        // TODO: find a better way
        setTimeout(
          function() {
            let $photo = null
            let $photoId = null
            switch(data.photo_event) {
              case 3:
                $photo = $('#id_photo')[0]
                $photoId = $('#id_photo_id')[0]
                break
              case 4:
                $photo = $('#user_photo')[0]
                $photoId = $('#user_photo_id')[0]
                break
            }
            $photo.src = data.photo_url
            $photoId.value = data.photo_id
            AccessHubVerificationController.goBack()
          },
          5000
        )
      },

      requestNewIdPhoto() {
        this.perform('request_new_id_photo', { access_hub_id: accessHubId })
      },

      requestNewUserPhoto() {
        this.perform('request_new_user_photo', { access_hub_id: accessHubId })
      },

      awaitingPhoto() {
        return $('.awaiting-photo[style*="display: block"]').length > 0
      },

      getVerificationStep() {
        console.log("getVerificationStep")
        return $('#ah-verification-container').data('step')
      },

      getPhotoEvent() {
        return $('#ah-verification-container').data('photo-event')
      },

      cancelNewIdPhotoRequest() {
        this.perform('cancel_new_id_photo_request', { access_hub_id: accessHubId })
      },

      cancelNewUserPhotoRequest() {
        this.perform( 'cancel_new_user_photo_request', { access_hub_id: accessHubId })
      },

      escalaste() {
        this.perform('escalate', { access_hub_id: accessHubId })
      },

    }

  );
}

$(document).ready(ahVerificationConnection)

