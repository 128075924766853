import {Controller} from "@hotwired/stimulus"

// Add the data-controller="dialog" to a <dialog> element!
export default class extends Controller {
  connect() {
    this.formElement = this.element.querySelector("form")

    // Close modal when clicking outside of it (works for modals only!)
    this.element.addEventListener("click", e => {
      e.target === this.element && this.close(e)
    })
  }

  show(e) {
    e.preventDefault()
    this.element.show()
  }

  showModal(e) {
    e.preventDefault()
    this.element.showModal()
  }

  close(e) {
    e.preventDefault()
    this.element.close()
    if (this.formElement) {
      this.formElement.reset()
    }
  }
}
