import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="g7-command-codes-form"
export default class extends Controller {
  static targets = ["locationSelect", "storageUnitSelect"]

  connect() {
    this.submitButton = this.element.querySelector('input[type="submit"]')
    this.storageUnitTypeField = this.element.querySelector('#g7_totp_code_form_storage_unit_type')

    this.submitButton.disabled = true
    this.storageUnitSelectTarget.disabled = true

    $(this.storageUnitSelectTarget).select2({
      templateSelection: function (item) {
        if (!item.id) return item.text

        let groupLabel = item.element.parentNode.getAttribute('label')
        if (groupLabel) {
          return `${groupLabel}: ${item.text}`
        } else {
          return item.text
        }
      }
    })

    $(this.locationSelectTarget).on('select2:select', (e) => this._processLocationChange(e))
    $(this.storageUnitSelectTarget).on('select2:select', (e) => this._processStorageUnitChange(e))
  }

  _processLocationChange(e) {
    let locationId = e.params.data["id"]
    this.storageUnitSelectTarget.disabled = true
    this.submitButton.disabled = true
    this.storageUnitSelectTarget.selectedIndex = -1
    $(this.storageUnitSelectTarget).val(null).trigger('change')
    this.storageUnitSelectTarget.innerHTML = ''

    if (locationId) {
      this._setStorageUnitOptions(locationId)
    }
  }

  _processStorageUnitChange(e) {
    let group = e.params.data.element.parentNode.getAttribute('label')
    if (!group) {
      this.submitButton.disabled = true
      this.storageUnitTypeField.value = ''
    } else {
      this.storageUnitTypeField.value = e.params.data.element.dataset.storageUnitType
      this.submitButton.disabled = false
    }
  }

  _setStorageUnitOptions(locationId) {
    $.when(
      $.ajax({
        type: 'get',
        dataType: 'json',
        url: '/g7_spaces?location_id=' + locationId,
      }),
      $.ajax({
        type: 'get',
        dataType: 'json',
        url: '/bike_hangars?location_id=' + locationId,
      })
    ).then((g7SpacesData, bikeHangarsData) => {
      this._processDataForOptions({bikeHangars: bikeHangarsData[0], g7Spaces: g7SpacesData[0]})
    })
  }

  _processDataForOptions(data) {
    if (!data || (data.bikeHangars.length === 0 && data.g7Spaces.length === 0)) {
      this.storageUnitSelectTarget.disabled = true
      return
    }

    this.storageUnitSelectTarget.prepend(new Option('Choose...', '', false, true))
    if (data.bikeHangars.length > 0) this._addStorageUnitOptGroup('BikeHangar', 'Bike Hangar', data.bikeHangars)
    if (data.g7Spaces.length > 0) this._addStorageUnitOptGroup('G7Space', 'G7 Space', data.g7Spaces)
    this.storageUnitSelectTarget.disabled = false
    $(this.storageUnitSelectTarget).val(null).trigger('change')
  }

  _addStorageUnitOptGroup(type, label, data) {
    const optGroup = document.createElement('optgroup')
    optGroup.label = label
    data.forEach((item) => {
      const option = document.createElement('option')
      option.value = item.id
      option.text = item.name
      option.defaultSelected = false
      option.selected = false
      option.dataset.storageUnitType = type
      optGroup.append(option)
    })
    this.storageUnitSelectTarget.append(optGroup)
  }
}
