import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="g7-spaces--batch-edit"
export default class extends Controller {
  connect() {
    this.ids = []
    this.dialogElement = document.querySelector('dialog#batchEdit')
    this.form = this.dialogElement.querySelector('form')

    this.element.addEventListener('click', (e) => {
      e.preventDefault();
      if(this.ids.length > 0) {
        this.dialogElement.showModal()
      } else {
        alert('Please select at least one row')
      }
    })

    document.querySelector('.datagrid').addEventListener('datagrid:selection-changed', (event) => {
      this.ids = event.detail.selectedRows
      this.form.querySelectorAll('input[type="hidden"][name="ids[]"]').forEach((field) => field.remove())
      this.ids.forEach((id) => this._addHiddenField(id))
    })

    this.form.querySelector('select#g7_pricing_type_id').addEventListener('change', (e) => {
      this._handlePricingTypeChange(e)
    })

    this.dialogElement.addEventListener('close', () => {
      this._hideAllPricing()
    })

    this.cardModelsSelect = this.dialogElement.querySelector('#supported_card_model_ids');
    $(document).ready(() => {
      $(this.cardModelsSelect).select2({width: '100%', placeholder: '<do not change>', dropdownParent: $('#batchEdit')})
    })
  }

  _handlePricingTypeChange(e) {
    let pricingId = e.target.value
    this._hideAllPricing()

    if (pricingId !== '' && pricingId !== undefined) {
      this._showPricing(pricingId)
    }
  }

  _hideAllPricing() {
    this.form.querySelectorAll('.pricing-field').forEach((el) => {
      el.style.display = 'none'
      el.querySelectorAll('input, select').forEach((el) => el.setAttribute('disabled', 'disabled'))
    })
  }

  _showPricing(id) {
    const pricing = this.form.querySelector(`.pricing-field#pricing_scheme_${id}`)
    pricing.style.display = 'block'
    pricing.querySelectorAll('input, select').forEach((el) => el.removeAttribute('disabled'))
  }

  _addHiddenField(id) {
    let field = document.createElement('input')
    field.setAttribute('type', 'hidden')
    field.setAttribute('name', 'ids[]')
    field.setAttribute('value', id)
    this.form.appendChild(field)
  }
}
