import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="datagrid"
// You can listen to `datagrid:selection-changed` event to get list of selected rows (ids) and its changes
export default class extends Controller {
  connect() {
    this._initDatepicker()
    this._initCheckboxes()
  }

  _initDatepicker() {
    this.element.querySelectorAll('form.datagrid-form .date_filter').flatpickr({
      locale: 'en',
      enableTime: false,
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F j, Y",
    })

    this.element.querySelectorAll('form.datagrid-form .date_time_filter').flatpickr({
      locale: 'en',
      enableTime: true,
      minuteIncrement: 1,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "m/d/Y G:i K",
    })
  }

  _initCheckboxes() {
    this.selectedRows = []

    this.mainCheckbox = this.element.querySelector('table.datagrid > thead input[type="checkbox"][name="row_selector"]')
    if (this.mainCheckbox) {
      this.mainCheckbox.addEventListener("change", this._toggleAllCheckboxes.bind(this))
    }

    this.rowCheckboxes = this.element.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]')
    if (this.rowCheckboxes.length > 0) {
      this.rowCheckboxes.forEach((checkbox) => checkbox.addEventListener("change", this._toggleCheckbox.bind(this)))
    }
  }

  _toggleAllCheckboxes(e) {
    this.selectedRows = []
    this.element.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]').forEach((checkbox) => {
      checkbox.indeterminate = false
      checkbox.checked = e.target.checked
      if (e.target.checked) this.selectedRows.push(checkbox.value)
    })

    this.dispatch('selection-changed', {detail: {selectedRows: this.selectedRows}})
  }

  _toggleCheckbox(e) {
    let totalItems = this.rowCheckboxes.length
    let checkedItems = this.element.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]:checked').length

    if (e.target.checked) {
      this.selectedRows.push(e.target.value)
    } else {
      this.selectedRows = this.selectedRows.filter((value) => value !== e.target.value)
    }

    if (totalItems === checkedItems) {
      this.mainCheckbox.indeterminate = false
      this.mainCheckbox.checked = true
    } else if (checkedItems > 0 && checkedItems < totalItems) {
      this.mainCheckbox.indeterminate = true
    } else {
      this.mainCheckbox.indeterminate = false
      this.mainCheckbox.checked = false
    }

    this.dispatch('selection-changed', {detail: {selectedRows: this.selectedRows}})
  }
}
