import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dialog-opener"
export default class extends Controller {
  static values = {
    dialogSelector: String,
    openMode: {type: String, default: 'dialog'}
  }

  connect() {
    if (!this.hasDialogSelectorValue) {
      console.error("No dialog selector specified!")
      return
    }

    this.dialogElement = document.querySelector(this.dialogSelectorValue)

    this.element.addEventListener("click", e => {
      e.preventDefault()
      if (this.dialogElement) {
        this._open()
      } else {
        console.error(`Dialog "${this.dialogSelectorValue}" not found!`)
      }
    })
  }

  _open() {
    if (this.openModeValue === 'modal') {
      this.dialogElement.showModal()
    } else {
      this.dialogElement.show()
    }
  }
}
