$(document).ready ->
  $('#filter_by_faq').autocomplete
    source: $('#filter_by_faq').data('autocomplete-source'),
    select: (event, ui) ->
      $.ajax(
        type: 'GET',
        url: '/help/faq' + '?faq_id=' + ui.item.value_for,
        data: ui,
        dataType: "script"
      )
