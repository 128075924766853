class DeviceResolver
  @mobileWidth: ->
    $(window).width() < 768

  @desktopWidth: ->
    !DeviceResolver.mobileWidth()

  @isTablet: ->
    /iPad|Android/i.test(navigator.userAgent) && !DeviceResolver.mobileWidth()

  @isMobile: ->
    /iPhone|iPod|Android/i.test(navigator.userAgent)

  @isMobileOrTablet: ->
    DeviceResolver.isTablet() || DeviceResolver.isMobile()

window.DeviceResolver = DeviceResolver