function tooltipTranslation() {
    var identifier = $(this).data('translate');

    var localData = "error";

    $.ajax('/tooltip_texts/translate', {
        data: {tooltip_text: {identifier: identifier}},
        async: false,
        success: function (data) {
            localData = data;
        }
    });

    return localData;
}

export {tooltipTranslation};
