import consumer from "./consumer"

function photoConnection() {
  if (!continueIfAllowed('send_lcc', ['select_contact_method_cage'])) return

  let accessHubId = $('#access-hub-id')[0].value;
  let $flashFailed = $('#failed')[0];
  let $flashSucceed = $('#succeed')[0];

  window.photoChannel = consumer.subscriptions.create(
    {
      channel: "PhotoChannel",
      access_hub_id: accessHubId
    },
    {
      connected() {
        console.log('connected photoConnection');
      },

      disconnected() {
        console.log('disconnected photoConnection');
      },

      hideFlashes() {
        $flashSucceed.style.display = 'none';
        $flashFailed.style.display = 'none';
      },

      received(data) {
        console.log('data received from photoConnection');
        console.log(data);

        switch (data.event) {
          case 'request_response':
            this.showRequestResponse(data.success, data.result);
            break;
          case 'new_photo':
            this.updatePhoto(data.photo_url, data.timestamp, data.result);
            break;
          default:
            break;
        }
       },

      requestCurrentPhoto() {
        this.perform('request_current_photo', { access_hub_id: accessHubId })
      },

      showRequestResponse(success, result) {
        this.hideFlashes();
        if (success == true) {
          if ($flashSucceed) {
            $('#succeed').children('p')[0].textContent = result;
            $flashSucceed.style.display = 'block';
          }
        } else {
          if ($flashFailed) {
            $('#failed').children('p')[0].textContent = result;
            $flashFailed.style.display = 'block';
          }
        }
      },

      updatePhoto(url, timestamp, result) {
        setTimeout(
          function() {
            $('#current-photo')[0].src = url;
            let $timestamp = $('#photo-timestamp')[0];
            if ($timestamp) $timestamp.textContent = timestamp
            if ($flashSucceed) {
              this.hideFlashes();
              $('#succeed').children('p')[0].textContent = result;
              $flashSucceed.style.display = 'block';
            }
          },
          5000
        )
      }

    }
  )
}

$(photoConnection);
