class PeopleController
  @blockPersonWarning: (e) ->
    if e.currentTarget.checked
      alert(I18n.t('people.cards.not_allowed_confirmation'))

  @confirmUserAgreement: (url) ->
    $.ajax({
      url: url,
      type: 'POST',
      data: {'person' : {
        'agreement_confirmed': true
      }},
      dataType: 'json',
      success: ->
        $('#new-user-agreement').remove()
      error: (jqXHR, textStatus, errorThrown) ->
        console.log(jqXHR)
        console.log(errorThrown)
        console.log(textStatus)
    })

$(document).ready ->
  $(document).on('click', '#new-agreement-ok', (event) ->
    event.preventDefault();
    PeopleController.confirmUserAgreement($(this).data('url'))
  )
  $(document).on('change', '.allowed_to_have_cards_check', (event) ->
    PeopleController.blockPersonWarning(event)
  )

module.exports = PeopleController
