import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="owners--locations--usage-report"
export default class extends Controller {

  connect() {
    window.addEventListener('pageshow', () => {
      this._resetHiddenFields();
      this._toggleSubmitButton();
    });

    document.addEventListener('datagrid:selection-changed', this._handleDataGridSelectionChanged);

    document.addEventListener('turbo:load', () => {
      this._toggleSubmitButton();
      document.querySelector('.datagrid').addEventListener('datagrid:selection-changed', this._handleDataGridSelectionChanged);
    });
  }

  _handleDataGridSelectionChanged = (event) => {
    this._resetHiddenFields();
    this._toggleSubmitButton();
  };

  _resetHiddenFields() {
    document.querySelectorAll('input[type="hidden"][name="location_ids[]"]').forEach((field) => field.remove());
    document.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]:checked').forEach((item) => this._addHiddenField(item.value));
  }


  _toggleSubmitButton() {
    this.rowCheckboxes = document.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]:checked')
    const submitButton = this.element.querySelector('input[type="submit"]')
    submitButton.disabled = !(this.rowCheckboxes.length > 0);
  }

  _addHiddenField(id) {
    let field = document.createElement('input')
    field.setAttribute('type', 'hidden')
    field.setAttribute('name', 'location_ids[]')
    field.setAttribute('value', id)
    this.element.appendChild(field)
  }
}