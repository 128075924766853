import {Controller} from "@hotwired/stimulus"
import debounce from "lodash.debounce"

// Connects to data-controller="owners--locations--locker-search"
export default class extends Controller {

  initialize() {
    this.search = debounce(this.search, 600).bind(this)
  }

  search(e) {
    e.preventDefault()
    this.element.requestSubmit()
  }
}
