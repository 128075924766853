/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import I18n from '../components/i18n-js/index.js.erb'

import {tooltipTranslation} from '../components/modern/tooltip_texts'
import '../components/modern/payments'
import '../components/modern/behavior/zip_san_diego'
import '../components/modern/behavior/password_check'
import '../components/modern/maps/custom_marker'
import '../components/modern/maps/custom_bubble'
import '../components/modern/maps/device_resolver'
import '../components/modern/maps/distance_services'
import '../components/modern/maps/list'
import '../components/modern/maps/map'
import '../components/modern/maps/search'
import '../components/modern/payment_button_toggler'

// clipper map
import '../components/modern/clipper_maps/clipper_map'

import '../components/modern/braintree_form.coffee.erb'
import '../components/modern/init'
import '../components/modern/menu'
import '../components/modern/payments'

import '../components/controllers/people_controller.js'

import 'select2'
import 'select2/dist/css/select2.css'

import 'bootstrap/dist/js/bootstrap'
jQuery.fn.bstooltip = jQuery.fn.tooltip;

require('jquery-datetimepicker')
require('jquery-chained/jquery.chained')
require("jquery-ui")
require('jstree');
require("stylesheets/modern.scss")

jQuery.fn.uitooltip = jQuery.fn.tooltip;
jQuery.fn.tooltip = jQuery.fn.bstooltip;

// https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
// jquery 3.x call load function different. This is fallback for legacy code to working
jQuery.fn.load = function (callback) {
    $(window).on("load", callback)
};

import 'controllers' // Stimulus controllers

Turbo.session.drive = false // make Turbo Drive opt-in - you need to use data-turbo="true" per-element
Rails.start()
ActiveStorage.start()

window.jQuery = jQuery;
window.$ = $;
window.I18n = I18n

import '../components/modern/behavior/faqs_autocomplete'

window.addEventListener('DOMContentLoaded', () => {
    $('.js-states').select2()
})
console.log(I18n.t('views.cards.types.bikelink_app'))

$(document).on('turbo:load', function () {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').not('[data-translate]').tooltip();

    window.setTimeout(function () {
        $(".alert-success.alert-dismissible").fadeTo(1000, 0).slideUp(1000, function () {
            $(this).remove();
        });
    }, 5000);
    $('[data-translate]').tooltip({title: tooltipTranslation, html: true});
    $("div.location-details").on("click", ".close-modal", function () {
        $("div.location-details").removeClass('show');
    });

    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
        }
        var $subMenu = $(this).next('.dropdown-menu');
        $subMenu.toggleClass('show');


        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-submenu .show').removeClass('show');
        });

        return false;
    });

    $('button.faq-button-toggler').click(function (e) {
        e.preventDefault();
        if ($(this).find('i.fa').hasClass('fa-chevron-down')) {
            $(this).find('i.fa').toggleClass('fa-chevron-down fa-chevron-up')
        } else {
            $(this).find('i.fa').toggleClass('fa-chevron-up fa-chevron-down')
        }
    });

    $('#filter_by_faq').click(function() {
        $(this).val('');
    });

    $('#filter_by_faq').on('keypress', function(e) {
        if(e.which == 13) {
           $.ajax({
               type: 'GET',
               url: '/help/faq?term=' + $('#filter_by_faq').val(),
               dataType: 'script'
           });
        }
    });
});

$(document).on('turbo:click', function () {
    $('.modal').hide();
    $('.modal-backdrop').hide();
});

