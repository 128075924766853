class DistanceServices

  @EARTH_RADIUS_MILES: 3958.8

  @degreesToRadians: (degrees) ->
    degrees * Math.PI / 180

  @distanceInBetweenGPSCoordinates: (position1, position2) ->
    dLat = DistanceServices.degreesToRadians(position2.latitude - position1.latitude)
    dLon = DistanceServices.degreesToRadians(position2.longitude - position1.longitude)

    a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) *
      Math.cos(DistanceServices.degreesToRadians(position1.latitude)) * Math.cos(DistanceServices.degreesToRadians(position2.latitude))
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    DistanceServices.EARTH_RADIUS_MILES * c

  @inBoundingBox: (topRightLat, topRightLng, bottomLeftLat, bottomLeftLng, pointLat, pointLong) ->
    if topRightLng < bottomLeftLng
      isLongInRange = pointLong >= bottomLeftLng || pointLong <= topRightLng
    else
      isLongInRange = pointLong >= bottomLeftLng && pointLong <= topRightLng
    pointLat >= bottomLeftLat && pointLat <= topRightLat && isLongInRange

window.distanceServices = DistanceServices
