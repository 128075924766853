class PasswordCheck
  constructor: (@container) ->
    @requirementLength = @container.find('[data-requirement="length"]')
    @requirementLetterNumber = @container.find('[data-requirement="number"]')
    @requirementSymbol = @container.find('[data-requirement="symbol"]')

    @passwordInput = @container.find("input[type='password']")
    @passwordInput.keyup (e) =>
      @checkPassword()

  checkPassword: ->
    value = @passwordInput.val()
    @setClass(@requirementLength, /.{8,}$/.test(value))
    @setClass(@requirementLetterNumber, /^(?=.*?[a-z0-9A-Z]).{1,}$/.test(value))
    @setClass(@requirementSymbol, /^(?=.*?[#?!@$%^&*+=_.,\-'()":;{}<>`§|\\\[\]]).{1,}$/.test(value))

  setClass: (container, success) ->
    if success
      container.addClass('success')
      @passwordInput.addClass('border border-success text-success')
    else
      container.removeClass('success')
      @passwordInput.removeClass('border border-success text-success')

ready = ->
  $("*[data-behavior=PasswordCheck]").each ->
    new PasswordCheck($(@))

$(document).on('turbo:load', ready)
