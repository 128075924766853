BRAINTREE_CLIENT = "https://js.braintreegateway.com/web/3.44.2/js/client.min.js"
BRAINTREE_HOSTED_FIELDS = "https://js.braintreegateway.com/web/3.44.2/js/hosted-fields.min.js"
BRAINTREE_DATA_COLLECTOR = "https://js.braintreegateway.com/web/3.44.2/js/data-collector.min.js"

class BraintreeForm
  KOUNT: false

  STYLES: {
    'input': {
      'font-size': '1rem',
      'font-family': 'Montserrat Regular, sans-sarif'
      'color': '#495057'
    },
    'input.invalid': {
      'color': 'red'
    },
    'input.valid': {
      'color': 'green'
    }
  }

  FIELDS: {
    number: {
      selector: '#braintree-card-number',
      placeholder: ''
    },
    cvv: {
      selector: '#braintree-cvv',
      placeholder: ''
    },
    expirationMonth: {
      selector: '#braintree-expiration-month',
      placeholder: 'MM'
    },
    expirationYear: {
      selector: '#braintree-expiration-year',
      placeholder: 'YYYY'
    }
  }

  constructor: (braintreeElement) ->
    @braintreeElement = braintreeElement
    braintree.client.create({ authorization: braintreeElement.attr('data-braintree-token') },
      @createClient.bind(this)
    )

  createClient: (err, clientInstance) ->
    return console.error err if err
    braintree.dataCollector.create({client: clientInstance, kount: @KOUNT}, @collectData) if @KOUNT
    braintree.hostedFields.create({client: clientInstance, styles: @STYLES, fields:@FIELDS}, @buildListeners.bind(this))

  collectData: (err, dataCollectorInstance) ->
    return console.error err if err
    $('#braintree_device_data').val(dataCollectorInstance.deviceData)

  buildListeners: (err, hostedFieldsInstance) ->
    return console.error err if err
    self = this
    @braintreeElement.closest('form').on('submit', (event) -> self.addSubmitListener(event, hostedFieldsInstance))

  addSubmitListener: (event, hostedFieldsInstance) ->
    if @validate(event, hostedFieldsInstance)
      event.preventDefault()
      self = this
      hostedFieldsInstance.tokenize((err, payload) -> self.tokenizeResponse(err, payload, event))

  tokenizeResponse: (err, payload, event) ->
    return console.error err if err
    $('#payment_method_nonce').val(payload.nonce)
    event.target.submit()

  validate: (event, hostedFieldsInstance) ->
    states = hostedFieldsInstance.getState().fields
    if !states['number'].isValid
      this.alertError(event, hostedFieldsInstance, "Please provide proper Credit or Debit card number!", 'number')
    else if !states['expirationMonth'].isValid
      this.alertError(event, hostedFieldsInstance, "Please provide proper Expiration date!", 'expirationMonth')
    else if !states['expirationYear'].isValid
      this.alertError(event, hostedFieldsInstance, "Please provide proper Expiration date!", 'expirationYear')
    else if !states['cvv'].isValid
      this.alertError(event, hostedFieldsInstance, "Please provide proper CVV code!", 'cvv')
    else
      true

  alertError: (event, hostedFieldsInstance, message, code) ->
    alert(message)
    hostedFieldsInstance.focus(code)
    event.preventDefault()
    event.stopPropagation()
    false

addLib = (url) ->
  $.ajax(url, { crossDomain: true, dataType: 'script' })

ready = ->
  braintreeElement = $('#braintree-card-form')
  if braintreeElement.length
    return new BraintreeForm(braintreeElement) if typeof braintree != 'undefined'
    $.when(addLib(BRAINTREE_CLIENT), addLib(BRAINTREE_HOSTED_FIELDS), addLib(BRAINTREE_DATA_COLLECTOR)).done(() ->
      new BraintreeForm(braintreeElement)
    )

$(document).on('turbo:load', ready)
$(document).on('turbo:before-cache', (event) ->
  $('#braintree-card-number').empty()
  $('#braintree-cvv').empty()
  $('#braintree-expiration-month').empty()
  $('#braintree-expiration-year').empty()
)
