import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab"]

  toggle(e) {
    this.tabTargets.forEach((tab) => {
      if (tab === e.target) {
        tab.classList.add('active')
      } else {
        tab.classList.remove('active')
      }
    })
  }
}
