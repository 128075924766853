import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="owners--locations--locker-codes"
export default class extends Controller {
  static values = {
    openDoorCodeUrl: String,
    endRentalCodeUrl: String
  }

  connect() {
    this.dialog = document.querySelector('dialog#lockerCodes')
    this.turboFrame = this.dialog.querySelector('turbo-frame')
  }

  showOpenDoorCode(e) {
    e.preventDefault()
    if (e.target.classList.contains('disabled')) return

    this.turboFrame.innerHTML = ''
    this.turboFrame.setAttribute('src', this.openDoorCodeUrlValue)
    this.dialog.show()
  }

  showEndRentalCode(e) {
    e.preventDefault()
    if (e.target.classList.contains('disabled')) return

    this.turboFrame.innerHTML = ''
    this.turboFrame.setAttribute('src', this.endRentalCodeUrlValue)
    this.dialog.show()
  }
}
