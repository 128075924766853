import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="problem-reports--lookup-person-results"
export default class extends Controller {
  static values = {
    url: String
  }
  connect() {
    this.element.querySelectorAll('tbody > tr').forEach(row => {
      row.addEventListener('click', () => {
        this._lookupPerson(row.dataset.resultParams)
      })
    })
  }
  _lookupPerson(params) {
    $.ajax({
      url: this.urlValue,
      type: 'POST',
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      },
      data: `person_lookup=&${params}`,
      success: function (res) {
        Turbo.renderStreamMessage(res)
        document.getElementById('lookupPersonResults').close()
      }
    })
  }
}
