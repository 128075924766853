import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["chargeNormalAmount", "amount"]

    connect() {
        this.toggleAmountInput()
    }

    toggleAmountInput() {
        const checkbox = this.chargeNormalAmountTarget
        const amountInput = this.amountTarget

        amountInput.disabled = checkbox.checked
    }
}