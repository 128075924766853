import consumer from "./consumer"

function currentPhotoConnection() {
  if (!continueIfAllowed('access_hubs', ['current_photo'])) return

  let accessHubId = $('#current-photo-container').data('access-hub-id')
  if (accessHubId <= 0) return false

  window.accessHubChannel = consumer.subscriptions.create(
    {
      channel: "AccessHubChannel",
      access_hub_id: accessHubId
    },
    {
      connected() {
        console.log('connected');
      },

      disconnected() {
        console.log('disconnected');
      },

      hideFlashes() {
        $('#succeed')[0].style.display = 'none';
        $('#failed')[0].style.display = 'none';
      },

      received(data) {
        switch (data.event) {
          case 'request_response':
            this.showRequestResponse(data.success, data.result);
            break;
          case 'new_photo':
            this.updatePhoto(data.photo_url, data.timestamp, data.result);
            break;
          default:
            break;
        }
      },

      requestCurrentPhoto() {
        this.hideFlashes();
        this.perform('request_current_photo', { access_hub_id: accessHubId })
      },

      showRequestResponse(success, result) {
        this.hideFlashes();
        if (success == true) {
          let $flash = $('#succeed')[0];
          let $text = $('#succeed').children('p')[0];
          $text.textContent = result;
          $flash.style.display = 'block';
        } else {
          let $flash = $('#failed')[0];
          let $text = $('#failed').children('p')[0];
          $text.textContent = result;
          $flash.style.display = 'block';
        }
      },

      updatePhoto(url, timestamp, result) {
        setTimeout(
          function() {
            let $photo = $('#currentPhoto')[0];
            let $timestamp = $('#timestamp')[0];
            $photo.src = url;
            $timestamp.textContent = timestamp;
            let $flash = $('#succeed')[0];
            let $text = $('#succeed').children('p')[0];
            $text.textContent = result;
            $flash.style.display = 'block';
          },
          5000
        )
      },

    }
  )
}

$(document).ready(currentPhotoConnection)

