import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  static targets = ["field", "toggleButton"]

  toggleVisible(e) {
    e.preventDefault()

    this.fieldTargets.forEach((field) => {
      if (field.type === "password") {
        field.type = "text"
        this.toggleButtonTarget.classList.remove('fa-eye')
        this.toggleButtonTarget.classList.add('fa-eye-slash')
        this.toggleButtonTarget.title = "Hide PIN"
      } else {
        field.type = "password"
        this.toggleButtonTarget.classList.remove('fa-eye-slash')
        this.toggleButtonTarget.classList.add('fa-eye')
        this.toggleButtonTarget.title = "Show PIN"
      }
    })
  }
}
