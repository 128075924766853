import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="locations--lockers"
export default class extends Controller {
  connect() {
    this.locationSelect = this.element.querySelector('select')

    $(document).ready(() => {
      $(this.locationSelect).select2({width: '100%'})
      $(this.locationSelect).on('change', () => {
        this.element.requestSubmit()
      })
    })
  }
}
