$(document).ready(function () {
    const checkBoxes = document.querySelectorAll('.terms-checkbox');
    const submitButton = document.getElementById('submit-payment-btn');

    checkBoxes.forEach((cb) => {
        cb.addEventListener('change', checkButtonStatus);
    });
    function checkButtonStatus() {
        const checkedCount = [...checkBoxes].filter((cb) => cb.checked);
        if (submitButton) {
            submitButton.disabled = checkedCount.length !== checkBoxes.length
        }
    }

    if (submitButton) {
        checkButtonStatus();
    }
});
